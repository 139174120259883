








































































import { IBaseModel } from "@/data/models/IBaseModel";
import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Location } from "vue-router";
import Loader from "./Loader.vue";
import { saveAs } from "file-saver";

@Component({
  components: {
    Loader
  }
})
export default class FormContainer extends Vue {
  @Prop({ default: false })
  public extraLoading!: boolean;

  @Prop({ default: "" })
  public title!: string;

  @Prop({ default: "" })
  public id!: string;

  @Prop()
  public item!: IBaseModel;

  @Prop()
  public provider!: DataProvider<any>;

  @Prop()
  public crud!: ICrudClient<any>;

  @Prop()
  public submit!: () => Promise<null>;

  @Prop()
  public additionalSubmit!: () => Promise<null>;

  @Prop()
  public inDialog!: boolean;

  @Prop()
  public extractions!: boolean;

  @Prop()
  public saveOnly!: boolean;

  @Prop()
  public component!: any;

  @Prop()
  public routeAfterSave!: (item: any) => Location;

  @Prop()
  public routeAfterSaveAndClose!: (item: any) => Location;

  @Prop()
  public transform!: (item: any) => any;

  @Prop()
  public controllerName!: string;

  @Prop()
  public donwloadName!: string;

  @Prop()
  public filters!: [];

  private close = false;

  @Watch("id")
  async onIdChange() {
    await this.initialize();
  }

  async initialize() {
    if (this.id && this.id !== "new" && this.provider) {
      this.$emit("update:item", await this.provider.fetchItemAsync(this.id));
    }
  }

  async save() {
    try {
      let res;
      if (!this.submit) {
        if (!this.crud) {
          throw "No CRUD property was set in FormContainer";
        }
        let tosubmit = this.item;
        if (this.transform) {
          tosubmit = this.transform(tosubmit);
        }
        if (
          this.id === "new" ||
          this.item.id === "00000000-0000-0000-0000-000000000000" ||
          !this.item.id
        ) {
          res = (await this.crud.addAsync([tosubmit]))[0];
          this.$emit("added", res);
        } else {
          res = await this.crud.saveAsync(tosubmit);
          this.$emit("edited", res);
        }
        this.$q.notify({
          message: "Item saved successfully!",
          color: "green-4",
          timeout: 2000
        });
      } else {
        res = await this.submit();
      }
      if (this.additionalSubmit) {
        await this.additionalSubmit();
      }
      this.$emit("save");
      //this.$emit("update:item", res);
      if (this.close) {
        this.$emit("close");
        if (this.routeAfterSaveAndClose) {
          const loc = this.routeAfterSaveAndClose(res);
          const resolved = this.$router.resolve(loc);
          if (resolved.href != this.$route.path) {
            this.$router.replace(loc);
          }
        }
      } else {
        if (this.routeAfterSave) {
          const loc = this.routeAfterSave(res);

          const resolved = this.$router.resolve(loc);
          if (resolved.href !== this.$route.path) {
            this.$router.replace(loc);
          }
        }
      }

      return res;
    } catch (e) {
      this.$q.notify({
        message: e,
        color: "red-4"
      });
      throw e;
    }
  }

  async download(name: string, filters: any) {
    const utc = new Date().toJSON().slice(0, 10);
    saveAs(await this.$service.extraction(name, filters),
    `${this.donwloadName}_${utc}.xlsx`);
  }
}
